import React from 'react';
import './dashboard.css';

const Dashboard = () => {
    return (
        <div className="coming-soon-container">
            <h1>Dashboard</h1>
            <p className="coming-soon-text">Coming soon...</p>
        </div>
    );
};

export default Dashboard;
