import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isFiltersListOpened: window.innerWidth >= 768
}
export const statesSlice = createSlice({
    name: "states", initialState, reducers: {
        toggleFiltersList: (state) => {
            state.isFiltersListOpened = !state.isFiltersListOpened;
        }
    }
})
export const {toggleFiltersList} = statesSlice.actions;
export default statesSlice.reducer;