import React, {useState, useEffect} from 'react';
import './filter-list-container.css';
import FilterDropDown from "../filter-drop-down/filter-drop-down";
import {useDispatch, useSelector} from "react-redux";
import {resetFilters} from "../../features/filtering/filterSlice";
import {toggleFiltersList} from "../../features/app-states/statesSlice";
import {getFiltersList} from "../../services/filtering";
import CloseIcon from '@mui/icons-material/Close';

function FiltersListContainer() {
    const filtersContainerState = useSelector(state => state.states.isFiltersListOpened);
    const [filters, setFilters] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getFiltersList().then(res => {
            setFilters(res.filters);
        });
    }, []);

    function getFiltersDropDowns(filters) {
        let dropDownList = [];
        for (let filter in filters) {
            dropDownList.push(<FilterDropDown title={filter} options={filters[filter]} key={filter}/>);
        }
        return dropDownList;
    }

    function handleToggleClick() {
        dispatch(toggleFiltersList());
    }

    return (
        <div className={`filters-container ${filtersContainerState ? 'adding' : 'removing'}`}>
            <div className="filters-header ">
                <button className="toggle-filters-btn block md:hidden" onClick={handleToggleClick}>
                    <CloseIcon />
                </button>
                <div className="filters-title">
                    Filters
                </div>
            </div>
            <div className="filters-body">
                <button className="reset-btn" onClick={() => {
                    dispatch(resetFilters());
                }}>
                    Reset Filters
                </button>
                <div className="filter-drowpdowns-container">
                    {getFiltersDropDowns(filters)}
                </div>
            </div>
        </div>
    );
}

export default FiltersListContainer;
