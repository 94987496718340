import React, { useEffect } from 'react';
import './form.css';

const Form = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://tally.so/widgets/embed.js";
        script.async = true;
        script.onload = () => {
            if (window.Tally) {
                window.Tally.loadEmbeds();
            }
        };
        document.body.appendChild(script);
    }, []);

    return (
        <div className="form-container">
            <div className="iframe-wrapper">
                <iframe
                    data-tally-src="https://tally.so/embed/3jLrl9?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
                    loading="lazy"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    title="Tally Form"
                    style={{ overflow: 'auto', borderRadius: '8px' }}
                ></iframe>
            </div>
        </div>
    );
};

export default Form;
