import React, {useCallback, useEffect, useState} from 'react';
import './navbar.css'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {useDispatch, useSelector} from "react-redux";
import {
    addFilter,
    changeSearchToken,
    removeFilter,
    resetCitiesFilter,
    resetSelectAll
} from "../../features/filtering/filterSlice";
import Logo from "../../assets/favicon.png"
import Select from 'react-select'
import {getCities} from "../../services/filtering";
import CloseIcon from "@mui/icons-material/Close";
import SearchBar from "../search-bar/SearchBar";

function Navbar() {
    useEffect(() => {
        getCities().then(res => {
            res.cities = res.cities.map((city) => {
                return {
                    value: city,
                    label: city
                }
            })
            setCities(res.cities);
        });
    }, []);
    const [cities, setCities] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [currentSearchToken, setCurrentSearchToken] = useState('');
    const [containerFocused, setContainerFocused] = useState(false);
    const selectedCitiesInFilters = useSelector((state) => state.filtering.filters['city']);
    const dispatch = useDispatch();


    const test = useCallback(() => {
        setSelectedCities(selectedCitiesInFilters)
    }, [selectedCitiesInFilters]);


    const handleSearchInputChange = (event) => {
        dispatch(changeSearchToken(event.target.value));
        setCurrentSearchToken(event.target.value);
    }
    const handleKeyPressed = (event) => {
        if (event.key === 'Enter' && event.target.value.trim()) {
            localStorage.setItem('lastSearches', JSON.stringify(currentSearchToken));
        }
    }
    const citySelected = (cities) => {
        if (cities.length === 0) {
            dispatch(resetCitiesFilter())
            return;
        }
        setSelectedCities(cities)
        const newItem = cities[cities.length - 1].value;
        let filterData = {
            filterName: newItem,
            filterStack: `City`
        }
        dispatch(addFilter(filterData));
    }

    return (
        <div className="navbar">
            <div className="title" onClick={() => {
                window.location.reload()
            }}>
                <img src={Logo} alt="Logo"/>
                <div>Stacks<span className="logo-span">.ps</span></div>
            </div>
            <div className="mobile hidden md:block">
                {/* Other components */}
                <SearchBar
                    containerFocused={containerFocused}
                    setContainerFocused={setContainerFocused}
                    handleSearchInputChange={handleSearchInputChange}
                    handleKeyPressed={handleKeyPressed}
                    placeholder="Company, technology, language..."
                />
            </div>
            <div className="actions">
                {/*<button className="actions-button">*/}
                {/*        <span className="material-symbols-outlined icon">*/}
                {/*        apps*/}
                {/*        </span>*/}
                {/*</button>*/}
            </div>
        </div>
    )
}

export default Navbar;