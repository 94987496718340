import React, {useEffect, useState, useContext, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import './home_page.css';
import Heart from '../../assets/Heart.svg';
import Filter from '../../assets/Filter.svg';
import FilterBlcak from '../../assets/FilterBlcak.svg';
import { changeSearchToken } from "../../features/filtering/filterSlice";
import trashImage from '../../assets/trash.svg';

import CloseIcon from '@mui/icons-material/Close';
import Card from "../../shared_components/card/card";
import NotFound from "../../shared_components/not-found/not-found";
import FiltersListContainer from "../../shared_components/filters-list-container/filters-list-container";
import FeedbackModal from "../../shared_components/feedback-modal/feedback-modal";
import {removeFilter, resetFilters} from "../../features/filtering/filterSlice";
import {toggleFiltersList} from "../../features/app-states/statesSlice";
import {getAllCompanies, searchAndFilter} from "../../services/filtering";
import CircularProgress from '@mui/material/CircularProgress';
import Footer from "../../shared_components/footer/footer";
import {AppContext} from '../../features/context';
import SearchBar from "../../shared_components/search-bar/SearchBar";

function HomePage() {
    const {
        searchInput,
        setResults,
        cardsData,
        setCardsData
    } = useContext(AppContext);

    const selectedFilters = useSelector((state) => state.filtering.filters);
    const searchToken = useSelector( (state) => state.filtering.searchToken);
    const filtersContainerState = useSelector(state => state.states.isFiltersListOpened);

    const [isFiltersContainerShowed, setIsFilterContainerShowed] = useState(filtersContainerState);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFiltersTags, setSelectedFiltersTags] = useState([])
    const [cardWidth, setCardWidth] = useState('initial');
    // useEffect(() => {
    //     setIsFilterContainerShowed(false)
    // }, []);


    const [containerFocused, setContainerFocused] = useState(false);

    const handleSearchInputChange = (event) => {
        dispatch(changeSearchToken(event.target.value));
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsFilterContainerShowed(false);
            }
            else {
                setIsFilterContainerShowed(true);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleKeyPressed = (event) => {
        if (event.key === 'Enter' && event.target.value.trim()) {
            dispatch(searchAndFilter(searchToken, selectedFilters));
        }
    };

    const openFeedback = () => {
        /*
                setIsModalOpen(true);
        */
        window.open('https://tally.so/r/3jLrl9', '_blank');
    }
    const closeModal = () => {
        setIsModalOpen(false);
    }
    const renderCards = () => {
        if (!cardsData && !isLoading) {
            return <NotFound/>
        } else if (isLoading) {
            return <div className="spinner-div"><CircularProgress/></div>
        }
        return <div className="card-container">{getCardsElements()}</div>;
    };

    const renderSelectedFilters = useCallback(() => {
        let tags = []
        for (let key in selectedFilters) {
            selectedFilters[key].map((filter) => (
                tags.push(<div className="filter-tag" key={filter}>
                    <div>{filter}</div>
                    <button className="remove-filter-btn" onClick={() => {
                        dispatch(removeFilter({
                            filterName: filter,
                            filterStack: key
                        }))
                    }}>
                        <CloseIcon style={{fontSize: 14}}/>
                    </button>
                </div>)))
        }
        setSelectedFiltersTags(tags)
    }, [selectedFilters, dispatch]);
    const getCardsElements = useCallback(() => {
        let res = [];
        cardsData?.map(cardData => (res.push(<Card
            key={cardData.company_name}
            style={{width: cardWidth}}
            company_name={cardData.company_name}
            logo={cardData.company_logo}
            slogan={cardData.city}
            stacks={cardData.stacks}
            searchInput={searchInput}
        />)))
        return res;
    }, [cardsData, searchInput, cardWidth]);

    let selectedFiltersContainer = <div className='selected-filters-container'>
        <div className="selected-filters">{selectedFiltersTags}</div>
        <button className='clear-all-btn hidden md:block' onClick={() => {
            dispatch(resetFilters())
        }}>Clear All
        </button>
        <button className='clear-all-btn block md:hidden' onClick={() => {
            dispatch(resetFilters())
        }}>
            <img src={trashImage} alt="trash image"/>
        </button>
    </div>
    useEffect(() => {
        const handleResize = () => {
            const timeToWait = filtersContainerState ? 0 : 200;

            if (window.innerWidth < 768) {
                setTimeout(() => {
                setIsFilterContainerShowed(false);
                }, timeToWait)
            } else {
                setTimeout(() => {
                    setIsFilterContainerShowed(true);
                 }, timeToWait)
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setIsLoading(true);

        if (Object.keys(selectedFilters).length === 0 && searchToken === '') {
            getAllCompanies().then(res => {
                setCardsData(res.results);
                setResults(res.results);
            }).then(() => {
                setIsLoading(false);
            });
        } else {
            searchAndFilter(searchToken, selectedFilters).then(res => {
                setCardsData(res.results);
                setResults(res.results);
            }).then(() => {
                setIsLoading(false);
            });
        }
        renderSelectedFilters()
    }, [searchToken, selectedFilters, renderSelectedFilters]);

    // useEffect(() => {
    //     const timeToWait = filtersContainerState ? 0 : 200;
    //     setTimeout(() => {
    //         setIsFilterContainerShowed(filtersContainerState)
    //     }, timeToWait)
    // }, [filtersContainerState]);
    useEffect(() => {
        const handleResize = () => {
            const containerWidth = document.querySelector('.card-container')?.offsetWidth;
            const baseCardWidth = 325;
            const margin = 10;
            const n = Math.floor(containerWidth / (baseCardWidth + margin * 2));
            const remainingSpace = containerWidth - n * (baseCardWidth + margin * 2);
            if (remainingSpace < 200) {
                setCardWidth(`${(containerWidth / n) - (margin * 2) + 2}px`);
            } else {
                const newN = n + 1;
                setCardWidth(`${(containerWidth / newN) - (margin * 2) + 2}px`);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="page-wrapper">
            <div className="home_page">
                <div className="mobile-body hidden md:block">
                    <div className="header">
                        <div>Welcome to Stacks: <span className="sub-header">Discover, Connect, Empower</span></div>
                    </div>
                    <div className="feedback-container">
                        <div className="feedback-message">
                            <span className="heart-emoji">💙</span> Loving Stacks.ps ? Tell us what you love and how we can
                            improve! by giving us
                            <span onClick={openFeedback}
                                  className='feedback-click'> Feedback</span>.
                        </div>
                        <button className="filter-button"
                            onClick={() => {
                                if (!isFiltersContainerShowed) {
                                    setIsFilterContainerShowed(!isFiltersContainerShowed)
                                    setTimeout(() => {
                                        dispatch(toggleFiltersList())
                                    }, 10)
                                } else dispatch(toggleFiltersList());
                                // dispatch(toggleFiltersList())
                        }}>
                            <img className='filter-icon' src={Filter} alt="Filter Icon"/>
                            <span>{isFiltersContainerShowed ? 'Hide Filters' : 'Show Filters'}</span>
                        </button>
                    </div>
                </div>
                <div className="page-body">
                    <div className=" block md:hidden">
                        <div className="mobile-search">
                            <SearchBar
                                containerFocused={containerFocused}
                                setContainerFocused={setContainerFocused}
                                handleSearchInputChange={handleSearchInputChange}
                                handleKeyPressed={handleKeyPressed}
                                placeholder="Search"
                                value={searchToken}
                            />

                            <button className="filter-button" onClick={() => {
                                console.log("before if else isFiltersContainerShowed:",isFiltersContainerShowed)
                                if (!isFiltersContainerShowed) {
                                    setIsFilterContainerShowed(!isFiltersContainerShowed)
                                    setTimeout(() => {
                                       // dispatch(toggleFiltersList())
                                    }, 10)

                                } else {
                                    dispatch(toggleFiltersList());
                                }

                            }}>
                                <img className='filter-icon' src={FilterBlcak} alt="Filter Icon"/>
                                <span className="hidden md:block">
                                    {isFiltersContainerShowed ? 'Hide Filters' : 'Show Filters'}
                                </span>
                            </button>
                        </div>
                    </div>
                    {selectedFiltersTags.length > 0 ? selectedFiltersContainer : ''}
                    <div className="main-container">
                        <div className="cards-container-wrapper">
                            {renderCards()}
                        </div>
                        {isFiltersContainerShowed ? <FiltersListContainer/> : null}
                    </div>
                </div>
                {isModalOpen && <FeedbackModal closeModal={closeModal}/>}
            </div>
            <Footer/>
        </div>
    )
}

export default HomePage;