import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BottomNav.css';
import buildings from '../../assets/buildings.svg';
import Frame from '../../assets/Frame.svg';
import diagram from '../../assets/diagram.svg';

const BottomNav = () => {
    const location = useLocation();
    const [activePage, setActivePage] = useState(location.pathname);

    useEffect(() => {
        setActivePage(location.pathname);
    }, [location]);

    const handlePageChange = (page) => {
        setActivePage(page);
    };

    return (
        <div className="block md:hidden">
            <div className="bottom-nav">
                <Link to="/"
                      className={`nav-link ${activePage === '/' ? 'active' : ''}`}
                      onClick={() => handlePageChange('/')}>
                    <div className={`nav-icon-container ${activePage === '/' ? 'active-bg' : ''}`}>
                        <img src={buildings} alt="Companies" className="nav-icon" />
                    </div>
                    Companies
                </Link>
                <Link to="/Dashboard"
                      className={`nav-link ${activePage === '/Dashboard' ? 'active' : ''}`}
                      onClick={() => handlePageChange('/Dashboard')}>
                    <div className={`nav-icon-container ${activePage === '/Dashboard' ? 'active-bg' : ''}`}>
                        <img src={diagram} alt="Dashboard" className="nav-icon" />
                    </div>
                    Dashboard
                </Link>
                <Link to="/Form"
                      className={`nav-link ${activePage === '/Form' ? 'active' : ''}`}
                      onClick={() => handlePageChange('/Form')}>
                    <div className={`nav-icon-container ${activePage === '/Form' ? 'active-bg' : ''}`}>
                        <img src={Frame} alt="Form" className="nav-icon" />
                    </div>
                    Form
                </Link>
            </div>
        </div>
    );
};

export default BottomNav;
