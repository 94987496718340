import React, {useContext} from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from "./pages/home_page/home_page";
import Navbar from "./shared_components/navbar/navbar";
import SearchWindow from "./shared_components/search-window/search-window";
import { AppContext } from './features/context';
import BottomNav from "./shared_components/botom-navbar/BottomNav";
import Dashboard from "./pages/dashboard/dashboard";
import Form from "./pages/form/form";

function App() {
    const {
        setSearchWindowVisible,
    } = useContext(AppContext);
    return (
    <div className="App">
        <Navbar/>
        <Router>
            <Routes>
                <Route path="/" element={<HomePage/>}></Route>
                <Route path="Dashboard" element={<Dashboard/>}></Route>
                <Route path="/Form" element={<Form/>}></Route>
            </Routes>
            <BottomNav />
        </Router>

    </div>
  );
}
export default App;