import {React, useState, useEffect, useRef} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';

function SimpleSlider(props) {
    const [selectedStackIndex, setSelectedStackIndex] = useState(0);
    const [isScrollable, setIsScrollable] = useState(false);
    const availableStacks = props.stacks.available_stacks;
    const elements = availableStacks.map((item) => {
        let itemName;
        if (item === 'programming_languages') itemName = 'Languages';
        else itemName = item.charAt(0).toUpperCase() + item.slice(1);

        return (<div className={`slider-element ${item}`} key={item}>
            {itemName}
        </div>);
    });

    const settings = {
        className: "center",
        centerMode: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: selectedStackIndex,
        centerPadding: "75px",
        afterChange: (index) => setSelectedStackIndex(index),
    };

    function getSelectedStackData() {
        const selectedStackName = availableStacks[selectedStackIndex];
        let items = [];
        props.stacks[selectedStackName].forEach((stackField) => {
            items.push(<div className="img-wrapper" key={stackField.name}>
                <img
                    className="result-item"
                    src={stackField.icon}
                    title={stackField.name}
                    alt={stackField.name}
                />
            </div>)
        })
        return items
    }

    function checkScrollAbility() {
        const selectedStackName = props.stacks.available_stacks[selectedStackIndex];
        const stackItems = props.stacks[selectedStackName] || [];
        const totalStacksWidth = stackItems.length * 59;
        const containerWidth = parseInt(props.width, 10) - 64;
        setIsScrollable(containerWidth < totalStacksWidth);
    }

    useEffect(() => {
        checkScrollAbility();
        window.addEventListener('resize', checkScrollAbility);
        return () => window.removeEventListener('resize', checkScrollAbility);
    }, [props.width, selectedStackIndex, props.stacks]);

    return (<>
        <div className="simple-slider">
            <Slider {...settings}>
                {elements}
            </Slider>
        </div>
        <div className={`slider-elements-wrapper ${isScrollable ? 'is-scrollable' : ''}`}>
            <div className="slider-elements">
                {getSelectedStackData()}
            </div>
        </div>
    </>);
}

export default SimpleSlider;