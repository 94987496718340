import React from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import './SearchBar.css'

const SearchBar = ({
                       containerFocused,
                       setContainerFocused,
                       handleSearchInputChange,
                       handleKeyPressed,
                       placeholder = "Search..."
                   }) => {
    return (
        <div className={`search-bar-container ${containerFocused ? 'focused' : ''} ${containerFocused ? '' : 'hidden'}`}
             onClick={(e) => {
                 e.stopPropagation();
             }}>
            <SearchOutlinedIcon className="search-icon" />
            <input
                id="input-element-id"
                onChange={handleSearchInputChange}
                onKeyUp={handleKeyPressed}
                className="search-bar"
                type="text"
                placeholder={placeholder}
                onFocus={() => setContainerFocused(true)}
                onBlur={() => setContainerFocused(false)}
            />
        </div>
    );
};

export default SearchBar;
